import dogecatoriginal from './dogecatoriginal.jpeg';
import dogecat_small from './dogecat_small.jpg';
import doge_and_cat from './doge_and_cat.jpg';
import reddit_dogecat from './reddit_dogecat.png';
import './App.css';
import Marquee from "react-fast-marquee";
import ProgressiveImage from 'react-progressive-graceful-image';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const notify = () => toast("COMING SOON ⏰");

  return (
    <div className="App">
      <ToastContainer theme="dark" position="top-center" />
      <header className="App-header">
        <h1 className='title'>WTF IS $DOGECAT?</h1>

        <p className='callout'>
          IT'S A CAT THAT LOOKS LIKE THE DOGE.
        </p>

        <a className='callout-secondary callout-link'
           href='https://dexscreener.com/solana/fyrdncyhhaladamnmwqawdeuj5vrd1dqisdldpzxtbac'
           style={{color: 'white'}}
             target="_blank"
             rel="noopener noreferrer"
             >
            DEXSCREENER
        </a>

        <p className='ca'>
          CONTRACT ADDRESS
        </p>   
        <p className='ca'>
          8egfXRhxWsCXekq5uLCmfkD2Qt7yu73Jo3kcxXKUpump
        </p>   
        <br/>

        <ProgressiveImage
            src={dogecatoriginal}>
            {(src, loading) => (loading ? (<div className="ph-item dogecat" style={{padding: 0, height: '1000px !important', width: '100%'}}><div className="ph-picture" style={{padding: 0, height: '1000px !important', maxWidth: '80%'}}></div></div>) : <img src={src} className="dogecat" alt="dogecat" />)}
        </ProgressiveImage>

        <ProgressiveImage
            src={dogecat_small}>
            {(src, loading) => (loading ? (<div className="ph-item dogecat_small" style={{padding: 0, height: '256px !important', width: '100%'}}><div className="ph-picture" style={{padding: 0, height: '256px !important', maxWidth: '80%'}}></div></div>) : <img src={src} className="dogecat_small" alt="dogecat_small" />)}
        </ProgressiveImage>

        <p className='main-paragraph'>
          IN THE DEPTHS OF THE BEAR MARKET, HOOPBOOPTOOT UPLOADED A PICTURE TO THE DOGECOIN SUBREDDIT WITH THE CAPTION:
          <br/>
          <br/>
          <a className="App-link" target="_blank" rel="noopener noreferrer" href="https://www.reddit.com/r/dogecoin/comments/11db24n/so_someone_told_me_i_have_the_cat_version_of_the/">'SO SOMEONE TOLD ME I HAVE THE CAT VERSION OF THE DOGE MEME'</a>.
          <br/>
          <br/>
          TWO YEARS LATER, WITH THE CONSENT OF HOOPBOOPTOOT HIMSELF, $DOGECAT WAS OFFICIALLY BORN.
        </p>

        {/* <p className='regular-text'>
          "So someone told me I have the cat version of the doge meme" - <a className="App-link" target="_blank" rel="noopener noreferrer" href="https://www.reddit.com/r/dogecoin/comments/11db24n/so_someone_told_me_i_have_the_cat_version_of_the/">HoopboopToot</a>
        </p> */}

        <div>
          {/* <p className='regular-text' style={{textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '3px'}}>Take a look for yourself.</p> */}
          <img src={doge_and_cat} className="dogecat-large" alt="dogecat-large" />
        </div>

        <p className='main-paragraph'>
          AND AFTER ALL THIS TIME, IT APPEARS THAT...
          <br/>
          <br/>
          THE CAT STILL LOOKS LIKE THE DOGE.
          <br/>
          <br/>
          <a className='App-link' href={`https://twitter.com/intent/tweet?text=${encodeURIComponent("wtf is $dogecat?\n\nhttps://dogecat.wtf")}`} target="_blank" rel="noopener noreferrer">SPREAD THE WORD</a>
        </p>
        <p>
          <p className='callout'>THE PROPHECY OF THE DOGECAT IS NOW A REALITY.</p>
          <br/>
          <br/>
        </p>

        <div style={{ borderTop: '1px solid white' }}>
          {/* <p className='regular-text' style={{textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '3px'}}>Take a look for yourself.</p> */}
          {/* <img src={reddit_dogecat} className="dogecat-large" alt="reddit_dogecat" /> */}

          <p className='small-text'>
            <h4 style={{fontWeight: 'bold'}}>LEGAL DISCLAIMER</h4>
            <br/>
            <p style={{fontWeight: 'bold', marginBottom: 0}}>Not a Security</p>
            <small>DOGECAT is a memecoin and is not intended to be, nor should it be construed as, a security or investment product. The DOGECAT token is for entertainment purposes only, and its creation and use do not involve or imply any offering or solicitation of investment in a financial asset. The DOGECAT token does not represent ownership in any business, project, or entity, and its value is subject to market dynamics, speculation, and community involvement, with no guarantee of returns.</small>

            <p style={{fontWeight: 'bold', marginBottom: 0}}>No Legal Liability</p>
            <small>The DOGECAT token, its creators, and all associated individuals are not liable for any legal claims, damages, or losses resulting from the use or acquisition of the token. Users who choose to buy, sell, or trade DOGECAT do so at their own risk and discretion.</small>

            <p style={{fontWeight: 'bold', marginBottom: 0}}>No Governmental Oversight</p>
            <small>DOGECAT and its creators have made no representations regarding the regulatory status of the DOGECAT token, and no government agency, including but not limited to the U.S. Securities and Exchange Commission (SEC) or any similar regulatory body in other jurisdictions, has approved or disapproved of DOGECAT or its underlying technologies. The DOGECAT token is not endorsed or regulated by any government or financial authority.</small>

            <p style={{fontWeight: 'bold', marginBottom: 0}}>Not Investment Advice</p>
            <small>Nothing on this website should be construed as financial advice, investment advice, or an endorsement of any particular investment strategy. The creators of DOGECAT make no representations regarding the future value or success of the token. All users are encouraged to do their own research and seek independent professional advice before engaging in any financial activity related to cryptocurrencies or tokens.</small>

            <p style={{fontWeight: 'bold', marginBottom: 0}}>No Legal or Financial Guarantees</p>
            <small>By engaging with DOGECAT, you acknowledge and accept that cryptocurrency investments are speculative, volatile, and highly risky. You agree that you are solely responsible for any financial decisions you make, including any losses you may incur as a result of engaging with DOGECAT or any other cryptocurrency. DOGECAT and its creators provide no financial, legal, or investment guarantees.</small>
          </p>
        </div>
      </header>

      <Marquee className="marquee" autoFill={true} speed={100}>
        <p style={{padding: '5px'}}>just confirmed... the cat still looks like the doge.</p>
      </Marquee>

      <div className='links-container'>
        <p>
          <a className='links' href="https://t.me/dogecatofficial28" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-telegram fa-custom" style={{ color: '#00ADB5' }}></i>
          </a>
          <a className='links' href="https://twitter.com/dogecatofficial" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-twitter fa-custom" style={{ color: '#00ADB5' }}></i>
          </a>
          <a className='links' href="https://www.reddit.com/r/dogecatofficial" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-reddit fa-custom" style={{ color: '#00ADB5' }}></i>
          </a>
          <a className='links' href="https://www.instagram.com/dogecat111" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-instagram fa-custom" style={{ color: '#00ADB5' }}></i>
          </a>
        {/* <a className='links' href="https://t.me/dogecatportal" target="_blank" rel="noopener noreferrer">
          <i className="fa-solid fa-file-contract" style={{ color: '#00ADB5' }}></i>
          <span className='link-text'>contract</span>
        </a>
        <a className='links' href="https://twitter.com/dogecattoken" target="_blank" rel="noopener noreferrer">
          <i className="fa-solid fa-chart-line" style={{ color: '#00ADB5' }}></i>
          <span className='link-text'>chart</span>
        </a> */}
        </p>
      </div>
    </div>
  );
}

export default App;
